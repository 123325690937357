<template>
  <div id="container" class="svg-container" align="center">
    <svg :key="keyVal" v-if="redrawToggle === true" :width="svgWidth" :height="svgHeight">
      <g>
        <rect
          v-for="item in data"
          class="histogram"
          :key="item[xKey]"
          :x="xScale(item[xKey])"
          :y="yScale(0)"
          :width="xScale.bandwidth()"
          :height="0"
        ></rect>
      </g>
    </svg>
  </div>
</template>

<script>
import { scaleLinear, scaleBand, threshold } from 'd3-scale';
import { max, min, bin } from 'd3-array';
import { selectAll } from 'd3-selection';
import { transition } from 'd3-transition';

export default {
  name: 'BarChart',
  props: {
    xKey: String,
    yKey: String,
    data: Array,
  },
  mounted() {
    this.svgWidth = document.getElementById('container').offsetWidth;
    this.AddResizeListener();
    this.AnimateLoad();
  },
  data: () => ({
    svgWidth: 0,
    redrawToggle: true,
    keyVal: 0,
  }),
  methods: {
    AnimateLoad() {
      selectAll('rect.histogram')
        .data(this.data)
        .transition()
        .delay((d, i) => {
          return i * 20;
        })
        .duration(150)
        .attr('y', (d) => this.yScale(d[this.yKey]))
        .attr('height', (d) => {
          const height = this.svgHeight - this.yScale(d[this.yKey]);
          return height > 0 ? height : 0;
        });

      this.$data.redrawToggle = true;
    },
    AddResizeListener() {
      // redraw the chart 300ms after the window has been resized
      window.addEventListener('resize', () => {
        this.$data.redrawToggle = false;
        setTimeout(() => {
          this.$data.svgWidth =
            document.getElementById('container').offsetWidth;
          this.AnimateLoad();
        }, 300);
      });

      window.addEventListener('resize', () => {
        this.$data.redrawToggle = false;
        setTimeout(() => {
          this.$data.svgWidth =
            document.getElementById('container').offsetWidth;
          this.AnimateLoad();
        }, 300);
      });
    },
  },
  watch: {
    data: function(newVal, oldVal) {
      this.keyVal = this.keyVal++;
      this.redrawToggle = false;
      this.AnimateLoad();
    },
  },
  computed: {
    dataMax() {
      return max( this.data, (d) => d[this.yKey] );
    },
    dataMin() {
      return min( this.data, (d) => d[this.yKey] );
    },
    xScale() {
      return scaleBand()
        .rangeRound( [0, this.svgWidth] )
        .padding( 0.1 )
        .domain( this.data.map((d) => d[this.xKey]) );
    },
    yScale() {
      const height = scaleLinear()
        .rangeRound( [this.svgHeight, 0] )
        .domain( [this.dataMin > 0 ? 0 : this.dataMin, this.dataMax] );
      return height;
    },
    svgHeight() {
      return this.svgWidth / 1.61803398875 / 2; // golden ratio
    },
  },
};
</script>

<style scoped>
.bar-positive {
  fill: red;
  transition: r 0.2s ease-in-out;
}


.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
}
</style>

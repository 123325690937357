










































































// --- Vue & Template imports ---

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// --- Models ---

// --- 3rd Party ---
import gsap from 'gsap';


export interface Category {
  name: string;
  value: string;
  count: number;
  subCategories: SubCategory[];
  brands: any[];
  disabled: boolean;
}

export interface SubCategory {
  name: string;
  value: string;
  id: number;
  count: number;
  disabled: boolean;
}

@Component
export default class SlideyMcMenu extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(Array) readonly items!: Category[];
  @Prop(String) readonly activeCategory!: string;
  @Prop(Array) readonly activeSubcategories!: string[];
  

  selectedCategory: Category = {
    name: '',
    value: '',
    count: -1,
    subCategories: [],
    brands: [],
    disabled: false,
  };

  selectedSubCategories: string[] = [];

  $refs!: {
    slideyMenu: HTMLFormElement,
    category: HTMLFormElement,
    categoryContent: HTMLFormElement,
    backIcon: HTMLFormElement,
  };

  timeline: any = false;

  //https://greensock.com/forums/topic/29098-timeline-with-scroll-trigger/

  // #region Functions used to display item information
    @Watch('activeCategory', {
      immediate: true,
      deep: true,
    })
    activeCategoryChange() {
      this.selectedCategory = this.items.find((item: Category) => item.value === this.activeCategory) || {
        name: '',
        value: '',
        count: -1,
        subCategories: [],
        brands: [],
        disabled: false,
      };
    }

    @Watch('activeSubcategories', {
      immediate: true,
      deep: true,
    })
    activeSubcategoriesChange() {
      this.selectedSubCategories = this.getSubCategories();
    }

    @Watch('items', {
      immediate: true,
      deep: true,
    })
    itemsChange() {
      this.selectedCategory = this.items.find((item: Category) => item.value === this.activeCategory) || {
        name: '',
        value: '',
        count: -1,
        subCategories: [],
        brands: [],
        disabled: false,
      };
      this.selectedSubCategories = this.getSubCategories();

      if (this.selectedCategory.value !== '') {
        gsap.set(this.$el, { height: this.getElementSize() });

        if (this.$refs.categoryContent) {
          gsap.set(
            this.$refs.categoryContent, {
              right: 0,
              position: 'relative',
            },
          );
        }
      } else {
        if (this.$refs.category) {
          gsap.set(
            this.$refs.category, {
              left: 0,
              position: 'relative',
            },
          );
        }
      }
    }
  // #endregion

  updated() {
    if (this.$el) {
        gsap.to(this.$el, {
          duration: 0.15, 
          height: 'auto',
        });
      }
  }

  getElementSize() {
    if (this.$refs.slideyMenu.clientHeight) {
      return this.$refs.slideyMenu.clientHeight;
    } else {
      return 'auto';
    }
  }

  getElementWidth() {
    return this.$refs.slideyMenu.clientWidth;
  }

  getSubCategories() {
    return  [...new Set(this.activeSubcategories)];
  }

  clickItem(value: string) {
    this.$emit('click:category', value);

    const elWidth = this.getElementWidth();

    gsap.set(this.$el, { height: this.getElementSize() });

    gsap.to(
      this.$refs.category, {
        duration: 0.15, 
        left: -elWidth,
        position: 'absolute',
      },
    );

    gsap.set(
      this.$refs.categoryContent, {
        right: 0,
        position: 'relative',
      },
    );

    gsap.from(
      this.$refs.categoryContent, {
        duration: 0.15, 
        right: -elWidth,
        onStart: () => {
          gsap.to(this.$el, {
            duration: 0.15, 
            height: 'auto',
          });
        },
      },
    );

  }

  clearCategory() {
    this.$emit('click:clearCategory');

    const elWidth = this.getElementWidth();

    gsap.set(this.$el, { height: this.getElementSize() });

    gsap.set(
      this.$refs.category, {
        left: 0,
        position: 'relative',
      },
    );

    gsap.from(
      this.$refs.category, {
        duration: 0.15, 
        left: -elWidth,
      },
    );

    gsap.set(
      this.$refs.categoryContent, {
        right: -elWidth,
        position: 'absolute',
      },
    );

    gsap.from(
      this.$refs.categoryContent, {
        duration: 0.15, 
        right: 0,
        onStart: () => {
          gsap.to(this.$el, {
            duration: 0.15, 
            height: 'auto',
          });
        },
      },
    );
  }

  filterSubCategory(value: string) {
    this.$emit('click:subCategory', this.selectedSubCategories);
  }

  onAnimationStart() {
    if (!this.timeline) {
      this.timeline = gsap.timeline({ repeat: -1, paused: true});
      this.timeline.to(this.$refs.backIcon.$el, { duration: 0.4, left: -8, ease: 'power1.out'});
      this.timeline.to(this.$refs.backIcon.$el, { duration: 0.6, left: 0, ease: 'power1.out'});
    }
    this.timeline.resume();
  }

  onAnimationEnd() {
    this.timeline.seek(0);
    this.timeline.pause();
  }
}











































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Models ---
import { EquipmentItem } from '@/models/Equipment.model';

// --- 3rd Party ---
import gsap from 'gsap';

@Component
export default class ProductCard extends Vue {
  @Prop(Object) readonly product!: EquipmentItem;
  @Prop(String) readonly productPath!: string;
  @Prop(Array) readonly tagFilter!: string[];
  @Prop(Number) readonly animationOrder!: number;

  uncacheImage: string = this.$store.getters['app/autoId'](new Date().getTime());

  getTagMatches(tag: string) {
    let matches = 0;

    for (const [tagIndex, tagText] of this.tagFilter.entries()) {
      if (tag.indexOf(tagText) > -1) {
        matches++;
      }
    }

    return matches;
  }

  getTags() {
    let localTags = this.product.searchTags ? this.product.searchTags.split(',') : [];

    if (this.product && this.product.searchTags) {
      if (this.tagFilter && localTags.length > 1) {
        const tags = localTags.map((item) => { 
          return {
            tag: item, 
            matches: this.getTagMatches(item.toLowerCase()),
          };
        }).sort((a, b) => b.matches - a.matches );

        localTags = tags.map((item) => item.tag);
      }
    } else {
      localTags = [];
    }

    return localTags;
  }

  updated() {
    const {productMain} = this.$refs;

    if (productMain && productMain) {
      gsap.set(this.$el, { opacity: 1 });

      gsap.from(
        this.$el, {
          duration: 0.4, 
          opacity: 0,
          delay: this.animationOrder * 0.1,
        },
      );
    }
  }

  // #region Functions to display equipment data on page load
  getEquipmentPhotoUrl() {
    // first check if the photo comes from the Equipment Type
    // if not, get it from the equipment
    // there should never not be one of them

    if (this.product.typeImageFilename) {
      // files for test data are in a local folder so they don't conflict with LIVE data
      return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment_type/' + this.product.typeImageFilename + '?tm=' + this.uncacheImage;
    } else if (this.product.imageFilename) {
      // files for test data are in a local folder so they don't conflict with LIVE data
      return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment/' + this.product.imageFilename + '?tm=' + this.uncacheImage;
    } else {
      return '';
    }
  }

  getBrandLogoUrl() {
    if (this.product.brandLogoFilename) {
      // files for test data are in a local folder so they don't conflict with LIVE data
      return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'brand_logos/' + this.product.brandLogoFilename + '?tm=' + this.uncacheImage;
    } else {
      return '';
    }
  }
}

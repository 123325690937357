














































































































































































































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProductCard from '@/components/app/ProductCard.vue';
import Histogram from '@/components/app/Histogram.vue';
import SlideyMcMenu from '@/components/app/SlideyMcMenu.vue';

// --- Models ---
import { EquipmentItem } from '@/models/Equipment.model';
import { Shoot, ShootType } from '@/models/Shoot.model';

// --- Services ---
import AppService from '@/services/app';
import EquipmentService from '@/services/equipment';
import ShootService from '@/services/shoot';

// --- Third Party imports ---
import dayjs from 'dayjs';
import gsap from 'gsap';
import Hashids from 'hashids';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

// --- Add Plugins to imports ---
gsap.registerPlugin(ScrollToPlugin);

// conf
export interface Filter {
  categories: string;
  subCategories: string[];
  shootTypeId: number;
  brands: string[];
  text: string;
}

//conf
export interface SortBy {
  name: string;
  value: string;
  id: number;
}

//conf
export interface Category {
  name: string;
  value: string;
  count: number;
  subCategories: SubCategory[];
  brands: Brand[];
  disabled: boolean;
}

export interface SubCategory {
  name: string;
  value: string;
  id: number;
  count: number;
  disabled: boolean;
}

export interface Brand {
  name: string;
  value: string;
  count: number;
  categoryIds: number[];
  subCategoryIds: number[];
}

export interface EquipmentFilterItem {
  equipmentItem: EquipmentItem;
  matches: number;
}

@Component({
  components: {
    ProductCard,
    Histogram,
    SlideyMcMenu,
  },
})
export default class EquipmentListing extends Vue {
  // used in listing for equipment Paths
  @Prop(String) readonly shootId!: string;
  // used to show shoot type selected from browser history
  @Prop(String) readonly shootTypeId!: string;

  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);
  browserHistoryNavigation: boolean = false;

  $refs!: {
    textSearchHelp: HTMLFormElement,
  };

  currentShoot: Shoot = {
    id: 0,
    name: '',
    shootTypeId: 0,
    shootType: '',
    regionId: 0,
    region: '',
    startDate: '',
    endDate: '',
    canDelete: false,
  };
  selectedShootTypeId: number = -1;

  isSidebar: boolean = false;

  textSearch: string = '';
  searchExpression: RegExp = /\w{3,}/g; // match words 3 chars or more
  priorToTextEquipmentLength: number = 0;
  searchArr: string[] = [];
  searchMatchCount: number = 0;
  searchModeActive: boolean = false;
  textBelowChars: boolean = false;
  textPhrase: string = '';
  
  // used by sort by select values
  selectedSortId: number = 0;
  previousSelectedSortId: number = 0;
  sortBy: SortBy[] = [
    {
      name: 'Daily Rate - Low to High',
      value: 'asc',
      id: 0,
    },
    {
      name: 'Daily Rate - High to Low',
      value: 'desc',
      id: 1,
    },
    {
      name: 'Category - A - Z',
      value: 'azcat',
      id: 2,
    },
    {
      name: 'Category - Z - A',
      value: 'zacat',
      id: 3,
    },
    {
      name: 'Brand - A - Z',
      value: 'azbrand',
      id: 4,
    },
    {
      name: 'Brand - Z - A',
      value: 'zabrand',
      id: 5,
    },
  ];

  // Available categories, subcategories and brands, used by facets
  availableFilters: any[] = [];
  availableBrands: any[] = [];
  selectedCategory: Category | undefined;

  filter: Filter = {
    categories: '',
    subCategories: [],
    shootTypeId: -1,
    brands: [],
    text: '',
  };
  oldFilter: Filter = {
    categories: '',
    subCategories: [],
    shootTypeId: -1,
    brands: [],
    text: '',
  };
  priceRange: number[] = [];
  priceRangeSlider: number[] = [];
  previousBrands: string[] = [];

  // used to filter equipment
  isLoading: boolean = true;
  equipmentData: EquipmentItem[] = [];
  equipmentFiltered: EquipmentFilterItem[] = [];

  // used by pricing filters
  pricingData: number[] = [];
  histogramData: any[] = [];
  equipmentPriceRange: number[] = [];
  categoryPriceRange: number[] = [];

  // data iterator config
  page: number = 1;
  itemsPerPage: number = 8;
  numberOfPages: number = 0;
  enabledBrands: any;
  shootTypesInList: ShootType[] = [];

  sideBarScrollPosition: number = 0;

  @Watch('filter', {
    immediate: true,
    deep: true,
  })
  filterChange(newFilter: Filter) {
    if (this.filter.shootTypeId > 0) {
      this.filterEquipment(this.getFilterChange(newFilter, this.oldFilter));
      this.oldFilter = JSON.parse(JSON.stringify(newFilter));
    }
  }

  @Watch('isSidebar', {
    immediate: true,
    deep: true,
  })
  sideBarChange() {
    if (this.isSidebar) {
      this.sideBarScrollPosition = document.getElementsByTagName('body')[0].getClientRects()[0].top;
      this.$nextTick(() => {
        document.getElementsByTagName('body')[0].style.position = 'fixed';
        document.getElementsByTagName('body')[0].style.top = this.sideBarScrollPosition + 'px';
        document.getElementsByTagName('body')[0].style.left = '0';
        document.getElementsByTagName('body')[0].style.right = '0';
      });
    } else {
      document.getElementsByTagName('body')[0].style.position = 'static';
      document.getElementsByTagName('body')[0].style.top = 'auto';
      document.getElementsByTagName('body')[0].style.left = 'auto';
      document.getElementsByTagName('body')[0].style.right = 'auto';

      this.$nextTick(() => {
        gsap.to(window, {duration: 0, scrollTo: {y: Math.abs(this.sideBarScrollPosition)}});
      });
    }
  }

  

  // #region Functions used for Text search
    onTextSearch(value: string) {
      if (value.length >= 3) {
        if (!this.filter.text || this.filter.text === '') {
          this.priorToTextEquipmentLength = this.equipmentFiltered.length;
        }
        this.searchArr = [... new Set(value.toLowerCase().match(this.searchExpression))];
    
        if (!this.searchModeActive) {
          this.sortBy = [...this.sortBy, ...[{
            name: 'Best Match',
            value: 'bestmatch',
            id: 6,
          }]];
          this.previousSelectedSortId = this.selectedSortId;
          this.selectedSortId = this.sortBy.length - 1;
        }

        this.searchModeActive = true;
        this.filter.text = value.toLowerCase();
      }

      if (value.length === 0) {
        this.filter.text = '';
        this.$nextTick(() => {
          gsap.to(this.$refs.textSearchHelp, {duration: 0.15, bottom: -30, opacity: 0, onComplete() {
            this.textPhrase = '';
          }});
        });
        this.searchModeActive = false;
      }
      
      if (value.length > 0 && value.length < 3) {
        this.textBelowChars = true;
        if (this.textPhrase === '') {
          gsap.set(this.$refs.textSearchHelp, {display: 'block', opacity: 0, bottom: -35});
        }
        this.textPhrase = 'Please enter at least 3 characters';
        this.$nextTick(() => {
          gsap.to(this.$refs.textSearchHelp, {duration: 0.15, bottom: -20, opacity: 1});
        });
      } else {
        this.textBelowChars = false;
        this.$nextTick(() => {
          gsap.to(this.$refs.textSearchHelp, {duration: 0.15, bottom: -30, opacity: 0, onComplete() {
            this.textPhrase = '';
          }});
        });
      }
    }
  
    getHeader() {
      // only add 6 days because it is 7 actual days so the initial date counts as one of the days
      return dayjs(this.currentShoot.startDate).format('DD MMM YYYY') + 
        (this.currentShoot.endDate !== this.currentShoot.startDate ? ' to ' + dayjs(this.currentShoot.endDate).format('DD MMM YYYY') : '');
    }

    getTextFilterMatches(item: EquipmentItem) {
      let matches = 0;
      // match words 3 letters or more only
      this.searchMatchCount = this.searchArr.length;

      for (const [searchIndex, searchTag] of this.searchArr.entries()) {
        if (searchTag && searchTag.length) {

          if (item.model.toLowerCase().includes(searchTag)) {
            matches++;
          }

          if (item.brand.toLowerCase().includes(searchTag)) {
            matches++;
          }

          if (item.searchTags && item.searchTags.toLowerCase().includes(searchTag)) {
            matches++;
          }
        }
      }

      return matches;
    }

    resetTextSearch() {
      // resets text search by default
      this.filter.text = '';
    }
  // #endregion

  // #region Functions used sort by
    onSortByClicked() {
      // sort both original dataset and filtered dataset
      if (this.sortBy[this.selectedSortId].value !== 'bestmatch') {
        this.previousSelectedSortId = this.selectedSortId;
      }
      
      this.sortEquipment(this.equipmentFiltered);
    }

    // actually sort the equipmentFiltered Array
    sortEquipment(equipmentArray: EquipmentFilterItem[]) {
      // shallow copy to trigger update to renders
      equipmentArray.sort((a, b) => {
        switch (this.sortBy[this.selectedSortId].value) {
          case 'desc': {
            return b.equipmentItem.currentRate - a.equipmentItem.currentRate;
          }
          case 'azcat': {
            return a.equipmentItem.category.toLowerCase() > b.equipmentItem.category.toLowerCase() ? -1 : 1;
          }
          case 'zacat': {
            return a.equipmentItem.category.toLowerCase() > b.equipmentItem.category.toLowerCase() ? 1 : -1;
          }
          case 'azbrand': {
            return a.equipmentItem.brand.toLowerCase() > b.equipmentItem.brand.toLowerCase() ? 1 : -1;
          }
          case 'zabrand': {
            return a.equipmentItem.brand.toLowerCase() > b.equipmentItem.brand.toLowerCase() ? -1 : 1;
          }
          case 'bestmatch': {
            return a.matches - b.matches;
          }
          default: {
            return a.equipmentItem.currentRate - b.equipmentItem.currentRate;
          }
        }
      });
    }
  // #endregion

  // #region Functions used by data iterator
    // page to the next page on the iterator
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1;
      }

      gsap.to(window, {duration: 0.4, scrollTo: {y: 0}});
    }

    // page to the previous page on the iterator
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
      }
    }
  // #endregion

  // #region Functions used by category, subcategory and brand facets
    resetCategory(triggerFilterChanged: boolean = true) {
      this.categoryPriceRange = [];
      this.selectedCategory = undefined;
      this.previousBrands = [];
      this.filter.categories = '';
    }

    // filter to category selected
    filterCategory(category: string) {
      this.selectedCategory = this.availableFilters.filter((item) => {
        return item.value === category;
      })[0];

      this.filter = {
        categories: category,
        subCategories: [],
        shootTypeId: this.filter.shootTypeId,
        brands: this.filter.brands,
        text: this.filter.text,
      };
      this.previousBrands = this.filter.brands ? this.filter.brands : [];

      this.resetPriceFilter();
    }

    filterSubCategory(subcategory: string[]) {
      this.previousBrands = [];

      this.filter = {
        categories: this.filter.categories,
        subCategories: subcategory,
        shootTypeId: this.filter.shootTypeId,
        brands: this.filter.brands, // reset brands
        text: this.filter.text,
      };

      this.resetPriceFilter();
    }

    filterBrand(brand: string) {
      this.resetPriceFilter();
    }
  // #endregion

  // #region Functions used for managing price filter & histogram
    resetPriceFilter() {
      if (!this.categoryPriceRange.length) {
        this.priceRange = [this.equipmentPriceRange[0], this.equipmentPriceRange[1]];    
      } else {
        this.priceRange = [this.categoryPriceRange[0], this.categoryPriceRange[1]];    
      }
    }

    resetPriceFilterClicked() {
      this.filterEquipment();
    }

    getPriceFilterActiveState() {
      return this.priceRange && (this.priceRange[0] !== this.equipmentPriceRange[0] || this.priceRange[1] !== this.equipmentPriceRange[1]);
    }

    // set minimum and maximum price, builds up histogram data
    setMinMaxPriceRange(equipmentArray?: EquipmentFilterItem[]) {
      // first get a nice clean array of all the pricing data

      const equipmentLocal = equipmentArray ? 
        [...equipmentArray].map((item) => item.equipmentItem) :
        [...this.equipmentFiltered].map((item) => item.equipmentItem);

      // if no equipment, don't update
      if (equipmentLocal.length) {
        // allow switching of which array to base the pricing on when filtering down
        this.pricingData = equipmentLocal.sort((a, b) => a.currentRate - b.currentRate ).map((d) => d.currentRate);
  
        // set up the priceRange with minimum and maximum values
        this.equipmentPriceRange = [
          Math.floor(this.pricingData[0]), 
          Math.ceil(this.pricingData[this.pricingData.length - 1]),
          (Math.ceil(this.pricingData[this.pricingData.length - 1]) - Math.floor(this.pricingData[0])) * 5 / 100,
        ];
  
        this.priceRangeSlider = [this.equipmentPriceRange[0], this.equipmentPriceRange[1]];
  
        // Start setting up to generate histogram bin data
        const bins = []; // init
        let binCount = 0; // counter
        // set up same interval as the price slider
        const interval = (Math.ceil(this.pricingData[this.pricingData.length - 1]) - Math.floor(this.pricingData[0])) * 5 / 100; 
        // same number of intervals as the price slider (each bucket will be a bar on the graph)
        const numOfBuckets = 20;
  
        // set up bin ranges & initial counts of 0
        for (let i = 0; i < numOfBuckets; i = i + 1) {
          bins.push({
            binNum: binCount,
            minNum: parseFloat(this.pricingData[0] + '') + (i * interval),
            maxNum: parseFloat(this.pricingData[0] + '') + ((i + 1) * interval),
            count: 0,
          });
          binCount++;
        }
  
        //Loop through data and add to bin's count
        for (const [pricingDataIndex, pricingDataItem] of this.pricingData.entries()) {
          for (const [binIndex, binItem] of bins.entries()) {
            const bin = bins[binIndex];
  
            if (
              (binIndex === 0 && pricingDataItem >= bin.minNum && pricingDataItem < bin.maxNum ) || 
              (pricingDataItem > bin.minNum && parseFloat(pricingDataItem + '') <= bin.maxNum )
            ) {
              bin.count++;
            }
          }  
        }
  
        // return histogram bin data in a format the graph can understand
        this.histogramData = bins.map( ( bin ) => {
          return { name: '' + bin.binNum, count: bin.count };
        });
      }
    }

    updateHistograph(value: any) {
      // use this function to update colors on the histogram
    }

    filterPrice(value: any) {
      this.priceRange = value;

      this.filterEquipment('price'); 
      this.priceRangeSlider = value;
    }
  // #endregion

  // #region filter function for equipment array
    getFilterChange(newFilter: Filter, oldFilter: Filter) {
      // Since the objects are the same structure, use simple if statements here to not complicate stuff
      if (newFilter.categories !== oldFilter.categories && (newFilter.categories !== '' || oldFilter.categories !== '')) {
        return 'categories';
      }

      if (JSON.stringify(newFilter.subCategories) !== JSON.stringify(oldFilter.subCategories)) {
        return 'subCategories';
      }

      if (newFilter.shootTypeId !== oldFilter.shootTypeId) {
        return 'shootTypeId';
      }

      if (JSON.stringify(newFilter.brands) !== JSON.stringify(oldFilter.brands)) {
        return 'brands';
      }

      if (newFilter.text !== oldFilter.text) {
        return 'text';
      }

      return '';
    }

    filterEquipment(filterChanged?: string) {
      this.selectedShootTypeId = this.filter.shootTypeId;
      if (this.filter.text === '' && filterChanged === 'text') {
        this.selectedSortId = this.previousSelectedSortId;
        this.searchModeActive = false;
        this.searchArr = [];
        this.textSearch = '';
        this.priorToTextEquipmentLength = -1;

        if (this.sortBy[this.sortBy.length - 1].value === 'bestmatch') {
          this.sortBy.splice(this.sortBy.length - 1, 1);
        }

        // text filter is cleared, refresh cats
        this.getAvailableFilters();
      } 

      this.equipmentFiltered = [];

      // copy equipment array;
      let localFilteredEquipment = [...this.equipmentData.filter(
        (item: EquipmentItem) => item.shootTypeId === this.filter.shootTypeId)].map(
          (item: EquipmentItem) => {
            const textFilterMatchCount = this.getTextFilterMatches(item);
            return {
              equipmentItem: item,
              matches: textFilterMatchCount,
            };
      });

      this.sortEquipment(localFilteredEquipment);

      if (
        (!this.browserHistoryNavigation && filterChanged !== 'text') || 
        (filterChanged === 'shootTypeId' && !this.$route.params.pathMatch)) {
        this.pathUpdateAfterFilter();
      }

      if (filterChanged !== 'shootTypeId') {
        if (this.filter.text !== '') {
          localFilteredEquipment = localFilteredEquipment.filter((item) => item.matches > 0);
        }

        // filter by category, subcategory, price and text
        if (this.filter.categories !== '') {
          localFilteredEquipment = localFilteredEquipment.filter((item) => {
            let filterResult = false;
  
            if (this.filter.categories !== '') {
              filterResult = this.filter.categories.indexOf(item.equipmentItem.category.toLowerCase()) > -1;
    
              // if the product hasn't been filtered yet, check against subcategory
              if (filterResult && this.filter.subCategories && this.filter.subCategories.length) {
                filterResult = this.filter.subCategories.indexOf(item.equipmentItem.subCategory.toLowerCase()) > -1;
              }
            }
            
            return filterResult;
          });
        }
  
        const includedBrands: string[] = [...new Set([
          ...localFilteredEquipment.map((item) => item.equipmentItem).reduce((
            list: string[], 
            current: EquipmentItem) => {
              list.push(current.brand.toLowerCase());
              return list;
            },
        [])])];
  
        const brandsInSet = includedBrands.filter((item) => this.filter.brands && this.filter.brands.includes(item));
  
        if (filterChanged === 'text' || this.filter.text !== '') {
          this.updateBrandStates(includedBrands);
        }

        if (filterChanged === 'text' && this.filter.text !== '') {
          this.getAvailableFilters(undefined, localFilteredEquipment.filter((item) => item.matches > 0));
        }
  
  
        // if (this.previousBrands) {
        //   includedBrands = includedBrands.filter((x: string) => {
        //     return this.previousBrands.includes(x);
        //   });
        // }

  
        // if the product hasn't been filtered yet, check against brand
        if (brandsInSet.length && this.filter.brands && this.filter.brands.length) {
          localFilteredEquipment = localFilteredEquipment.filter((item) => {
            return this.filter.brands && this.filter.brands.indexOf(item.equipmentItem.brand.toLowerCase()) > -1;
          });
        }
      }

      this.setMinMaxPriceRange(localFilteredEquipment);

      if (filterChanged === 'price') {
        // check filter changed, if anything but price, reset price
        // at this point set max/min price values
        if (this.priceRange && this.priceRange.length && this.priceRange[0]) {
          localFilteredEquipment = localFilteredEquipment.filter((item) => {
            if (this.priceRange && this.priceRange.length) {
              return item.equipmentItem.currentRate >= this.priceRange[0] && item.equipmentItem.currentRate <= this.priceRange[1];
            } else {
              return true;
            }
          });
        }
      } else {
        this.resetPriceFilter();
      }

      this.numberOfPages = Math.ceil(localFilteredEquipment.length / this.itemsPerPage);

      // using for loops here is actually most efficient, using map, filter and sort functions loops through the arrays 3x
      this.equipmentFiltered = localFilteredEquipment;
      
      if (filterChanged !== 'text' && this.filter.text === '') {
        this.getAvailableBrands(); // refresh brand list
      }

      this.updateCategoryStates();

      if (this.browserHistoryNavigation) {
        this.browserHistoryNavigation = false;
      }
    }

    clearCategories() {
      this.filter.categories = '';
    }

    resetFilterState() {
      const localFilter = {
        categories: '',
        subCategories: [],
        shootTypeId: this.filter.shootTypeId,
        brands: [],
        priceRange: [],
        text: '',
      };

      this.textSearch = '';
      this.categoryPriceRange = [];
      this.selectedCategory = undefined;
      this.browserHistoryNavigation = true;
      this.filter = localFilter;
    }
  // #endregion

  // #region Functions required for reading and manipulating path for filtering
    pathUpdateAfterFilter() {
      const filterPath = ['shoot'];
      filterPath.push(this.shootId + ''); // shootId is already encoded
      filterPath.push('equipment');

      if (this.filter.shootTypeId && this.filter.shootTypeId !== -1) {
        filterPath.push(this.hashids.encode(this.filter.shootTypeId + ''));
      }

      // construct a new url to push to history
      if (this.filter.categories) {
        filterPath.push('category');
        filterPath.push(this.filter.categories);

        if (this.filter.subCategories && this.filter.subCategories.length) {
          filterPath.push('subcategory');
          filterPath.push(this.filter.subCategories.join('+'));
        }
      }
      
      if (this.filter.brands && this.filter.brands.length) {
        filterPath.push('brands');
        filterPath.push(this.filter.brands.join('+'));
      }

      history.pushState('', '', '/' + filterPath.join('/'));
    }

    loadFiltersFromPath() {
      this.categoryPriceRange = [];

      const localFilter: Filter = {
        categories: '',
        subCategories: [],
        brands: [],
        shootTypeId: -1,
        text: '',
      };
    
      // filter for shootType
      // get shootTypeId from the pathMatch as the router has been changed to allow history on this value
      const currentShootTypeMatch = this.$route.path.match(/equipment\/\w*/);

      if (currentShootTypeMatch && currentShootTypeMatch.length) {
        const currentShootTypeIdHashed = currentShootTypeMatch ? currentShootTypeMatch[0].split('/')[1] : [];

        if (currentShootTypeIdHashed) {
          const currentShootTypeId = this.hashids.decode(currentShootTypeIdHashed + '');
          
          localFilter.shootTypeId = parseInt(currentShootTypeId + '', 10);
        }
      } else {
        localFilter.shootTypeId = this.currentShoot.shootTypeId;
      }

      this.getAvailableFilters(localFilter.shootTypeId);

      if (this.$route.params.pathMatch) {
        // filter for /category/
        let category = this.$route.params.pathMatch.match(/category\/\w*/);
  
        if (category && category.length) { 
          category = category[0].split('/');
          const categoryName = category[1];

          if (categoryName) {
            const checkCategory = this.availableFilters.filter((item) => {
              return item.value === categoryName;
            })[0];
            if (checkCategory) {
              this.selectedCategory = checkCategory;
              localFilter.categories = categoryName;
            }
          }
        }

  
        // filter for /subcategory/
        if (localFilter.categories) {
          let subCategory = this.$route.params.pathMatch.match(/subcategory\/\w*(\+\w*)*/);
      
          if (subCategory && subCategory.length && localFilter.categories) { 
            subCategory = subCategory[0].split('/');
            const subCategoryNames = subCategory[1].split('+');
      
            if (subCategoryNames.length && this.selectedCategory) {
              const availableSubCategories: string[] = this.selectedCategory.subCategories.map(({value}) => value);
              localFilter.subCategories = [...new Set(availableSubCategories.filter((element) => subCategoryNames.includes(element)))];
            }
          }
  
          let brands = this.$route.params.pathMatch.match(/brands\/\w*(\+\w*)*/);
      
          if (brands && brands.length && localFilter.categories) { 
            brands = brands[0].split('/');
            const brandNames: string[] = brands[1].split('+');
      
            if (brandNames.length && this.selectedCategory) {
              const availableBrands = this.selectedCategory.brands.map(({value}) => value);
              localFilter.brands = [...new Set(availableBrands.filter((element) => brandNames.includes(element)))];
            }
          }
        } else {
          let brands = this.$route.params.pathMatch.match(/brands\/\w*(\+\w*)*/);
      
          if (brands && brands.length) { 
            brands = brands[0].split('/');
            const brandNames = brands[1].split('+');
      
            if (brandNames.length) {
              const availableBrands = this.availableFilters.reduce((brands, current) => {
                const categoryBrands = current.brands.reduce((list: string[], current: any) => {
                  list.push(current.value);
                  return list;
                }, []);

                for (const categoryBrandItem of categoryBrands) {
                  brands.push(categoryBrandItem);
                }
  
                return brands;
              }, []);
  
              const brandUPDATE = [...new Set([...availableBrands.filter((element: string) => brandNames.includes(element))])];
  
              localFilter.brands = brandUPDATE;
            }
          }
        }
      }

      // load up the available category and subcategory filters
      this.filter = localFilter;
    }
  // #endregion

  // #region Functions required for setting up initial data for facets
    getAvailableFilters(shootTypeId?: number, localEquipment?: EquipmentFilterItem[]) {
      const filterEquipment = localEquipment ? 
        localEquipment.filter((item: EquipmentFilterItem) => item.matches > 0).map((item: EquipmentFilterItem) => item.equipmentItem) :
        [...this.equipmentData.filter((item: EquipmentItem) => item.shootTypeId === (shootTypeId ? shootTypeId : this.filter.shootTypeId))];

      // structure the array in preparation for the reduce
      this.availableFilters = filterEquipment.map((item) => {
        return {
          name: item.category,
          value: item.category.toLowerCase(),
          count: 1,
          disabled: false,
          id: item.categoryId,
          subCategories: [{
            name: item.subCategory,
            value: item.subCategory.toLowerCase(),
            id: item.subCategoryId,
            count: 1,
            disabled: false,
          }],
          brands: [{
            name: item.brand,
            value: item.brand.toLowerCase(),
            count: 1,
            categoryIds: [item.categoryId],
            subCategoryIds: [item.subCategoryId],
          }],
        };
      });

      const availableFilters = [...this.availableFilters];

      // this beast reduces all of the product categories into a nice array of category objects with
      // subcategory and brand arrays with their names, value and count
      this.availableFilters = availableFilters.reduce(
        (list, obj) => {
          let found = false;

          for (const [listIndex, listItem] of list.entries()) {
            if (list[listIndex].name === obj.name) {
              list[listIndex].count++;
              found = true;

              list[listIndex].subCategories = [...listItem.subCategories, obj.subCategories[0]].reduce(
                (subList, subObj) => {
                  let foundSubCat = false;

                  for (const [subListIndex, subListItem] of subList.entries()) {
                    if (typeof subList[0] !== typeof undefined  && subListItem.name === subObj.name) { 
                      subList[subListIndex].count = subListItem.count + 1;
                      foundSubCat = true;
                    }
                  }

                  if (!foundSubCat && subObj) {
                    subList.push(subObj);
                  }

                  return subList;
                }, [],
              );

              list[listIndex].brands = [...listItem.brands, obj.brands[0]].reduce(
                (subList, subObj) => {
                  let foundBrand = false;

                  for (const [subListIndex, subListItem] of subList.entries()) {
                    if (typeof subList[0] !== typeof undefined  && subListItem.name === subObj.name) { 
                      subList[subListIndex].count = subListItem.count + 1;
                      foundBrand = true;
                    }                 
                    
                  }

                  if (!foundBrand && subObj) {
                    subList.push(subObj);
                  }

                  return subList;
                }, [],
              );
            }
          }

          if (!found) {
            list.push(obj);
          }
          return list;
        }, [],
      );
    }

    getAvailableBrands() {
      const currentEquipment = this.equipmentData.filter((item: EquipmentItem) => item.shootTypeId === this.filter.shootTypeId).map((item) => {
        return {
          name: item.category,
          value: item.category.toLowerCase(),
          count: 1,
          disabled: false,
          id: item.categoryId,
          subCategories: [{
            name: item.subCategory,
            value: item.subCategory.toLowerCase(),
            id: item.subCategoryId,
            count: 1,
            disabled: false,
          }],
          brands: [{
            name: item.brand,
            value: item.brand.toLowerCase(),
            count: 1,
            categoryIds: [item.categoryId],
            subCategoryIds: [item.subCategoryId],
            disabled: false,
          }],
        };
      });
      let currentCategory = JSON.parse(JSON.stringify(currentEquipment));

      if (this.filter.categories) {
        currentCategory = currentCategory.filter(({value}: any) => {
          return value === this.filter.categories;
        });

        if (this.filter.subCategories && this.filter.subCategories.length) {
          for (const [currentCategoryIndex, currentCategoryItem] of currentCategory.entries()) {
            currentCategory[currentCategoryIndex].subCategories = currentCategoryItem.subCategories.filter(({value}: any) => {
              return this.filter.subCategories && this.filter.subCategories.indexOf(value) > -1;
            });
          }
        }
      }

      const availableFiltersCopy: any[] = JSON.parse(JSON.stringify(currentCategory));

      this.availableBrands = availableFiltersCopy.map(({brands}) => brands).reduce((brandList, brandObj) => {
        for (const brand of brandObj) {
          let foundBrand = false;

          for (const [brandListIndex, brandListItem] of brandList.entries()) {
            if (typeof brandList[0] !== typeof undefined && brandListItem.name === brand.name) { 
              brandList[brandListIndex].count = brandListItem.count + brand.count;
              brandList[brandListIndex].categoryIds = [...new Set([...brandListItem.categoryIds, ...brand.categoryIds])];
              brandList[brandListIndex].subCategoryIds = [...new Set([...brandListItem.subCategoryIds, ...brand.subCategoryIds])];
 
              foundBrand = true;
            }
          }

          if (!foundBrand && brand) {
            brandList.push(brand);
          }
        }

        return brandList;
      }, []);
    }

    getAvailableShootTypes() {
      const localShootTypes = this.equipmentData.reduce((shootTypeList: ShootType[] , equipmentItem) => {
        const localIds = shootTypeList.reduce((idList: string[], shootType) => {
          if (idList.indexOf(shootType.id + '') === -1) {
            idList.push(shootType.id + '');
          }
          return idList;
        }, []);

        if (localIds.indexOf(equipmentItem.shootTypeId + '') === -1) {
          shootTypeList.push({
            name: equipmentItem.shootType,
            id: equipmentItem.shootTypeId,
            equipmentCount: 1,
          });
        } else {
          for (const [shootTypeIndex, shootType] of shootTypeList.entries()) {
            if (shootType.id === equipmentItem.shootTypeId) {
              shootTypeList[shootTypeIndex].equipmentCount++;
            }
          }
        }

        return shootTypeList;
      }, []);

      return localShootTypes;
    }

    getEnabledBrands() {
      const enabledBrands = this.availableBrands.reduce((brandsList, brand) => {
        if (!brand.disabled) {
          brandsList.push(brand);
        }
        return brandsList;
      }, []) || [];

      return enabledBrands;
    }

    updateCategoryStates() {
      if (this.filter.brands.length && this.availableBrands.length > 1) {
        const localAvailableBrands = JSON.parse(JSON.stringify(this.availableBrands));

        const brandCatIds = localAvailableBrands.length ? 
        [... new Set(
          localAvailableBrands.filter(
            (brand: any) => this.filter.brands.indexOf(brand.name.toLowerCase()) > -1).map(
              (brand: any) => brand.categoryIds).reduce(
                (prev: number[], current: number[]) => [...prev, ...current]))] :
        [];

        for (const [index, filter] of this.availableFilters.entries()) {
          if (brandCatIds.indexOf(filter.id) === -1) {
            this.availableFilters[index].disabled = true;
          } else {
            this.availableFilters[index].disabled = false;
          }
        }
      } else {
        for (const [index, filter] of this.availableFilters.entries()) {
          this.availableFilters[index].disabled = false;
        }
      }
    }

    updateBrandStates(brandsInSet: string[]) {
      for (const availablebrandItem of this.availableBrands) {
        availablebrandItem.disabled = brandsInSet.indexOf(availablebrandItem.name.toLowerCase()) === -1;
      }
    }
  // #endregion

  // #region Functions used for viewing equipment detail in this listing
    viewItem(itemId: number) {
      this.$router.push({ name: 'View Item', params: { id: '' + this.hashids.encode(itemId) } } ); 
    }
  // #endregion

  // #region Functions used shoot type selection
    clickShootType(shootTypeId: number) {
      this.selectedShootTypeId = shootTypeId;

      // trigger shoot type selected
      this.onShootTypeSelected();
    }

    async onShootTypeSelected() {
      const localFilter: Filter = {
        categories: '',
        subCategories: [],
        brands: [],
        shootTypeId: this.selectedShootTypeId,
        text: '',
      };

      this.textSearch = '';
      this.page = 1;
      
      this.filter = localFilter;
      this.getAvailableFilters();
    }
  // #endregion

  // #region Functions used for initial page setup
    async beforeMount() {
      await ShootService.listTypes(false);
      this.shootTypesInList = this.$store.getters['shoots/shootTypes'].filter((item: ShootType) => item.equipmentCount > 0);

      // get shootId from the prop
      const shootIdStr: string = this.hashids.decode(this.shootId + '') + '';

      if (shootIdStr && shootIdStr.length > 0) {
        await ShootService.listAll(false);

        const index = this.$store.getters['shoots/shoots'].findIndex((item: Shoot) => item.id  === parseInt(shootIdStr, 10));
        if (index >= 0) {
          // we found the shoot!
          this.currentShoot = this.$store.getters['shoots/shoots'][index];

          await this.listAvailableEquipmentItemsByShoot();

          if (this.equipmentData) {
            // set default sort low to high
            this.selectedSortId = 0;

            this.shootTypesInList = this.getAvailableShootTypes();

            // check if path contains params, if it does, set them to the filter
            this.loadFiltersFromPath();

            // trigger popstate change to look for new filter data from the url
            window.onpopstate = () => {
              if (this.$route.params.pathMatch) {
                this.browserHistoryNavigation = true;
                this.loadFiltersFromPath();
              } else {
                this.resetFilterState();
              }
            };
          } else {
            // this would have been handled in listEquipmentItems below
          }
        } else {
          // redirect them back to shoot listing because they need to select an active shoot first
          // they could have a page stored in their briwser history for a shoot that is in the past that they can no longer work on
        this.$router.push({ name: 'My Shoots'});
        }
      } else {
        // redirect them back to shoot listing because they need to select a shoot first
        this.$router.push({ name: 'My Shoots'});
      }    
    }

    async listAvailableEquipmentItemsByShoot() {
      this.isLoading = true;
      await EquipmentService.listAvailableByShoot({ params: { shootId: this.currentShoot.id }})
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              this.equipmentData = response.data;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('Equipment_Listing.vue - listAvailableEquipmentItemsByShoot - 1243 - ' + JSON.stringify(response));
          }
        })
        .finally(() => { 
          this.isLoading = false;
        });
    }
  // #endregion


  // #region Functions used to handle no results
  backToReferredPage() {
      if (this.shootId) {
        // refer back to shoot the user came from
        this.$router.push({ name: 'Shoot Detail', params: { id: '' + this.shootId } } ); 
      } else {
        // default if none of the previous redirects occur, just trigger browser back
        window.history.back();
      }
    }

    isShootTypeAvailable() {
      return this.shootTypesInList.filter((item: ShootType) => item.id === this.filter.shootTypeId).length > 0;
    }
  // #endregion
}
